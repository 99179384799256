// Initial data for state .:This is need refactor from back:.
export const typesProperties = [
    {
        _id: "62a989ab1f2c2bbc5b1e7153",
        name: "Color",
    },
    {
        _id: "62a989e21f2c2bbc5b1e7154",
        name: "Size",
    }
]

export const property_to_id = {
    color: "62a989ab1f2c2bbc5b1e7153",
    size: "62a989e21f2c2bbc5b1e7154",
    "custom property": undefined,
}